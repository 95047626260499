import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { WalletMultiButton } from '@solana/wallet-adapter-material-ui';
import { useState } from 'react';
import { Container, IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';


const Header = () => {

  const pages = [{ name: 'Snatchers', url: '/snatchers' }, { name: 'Hellhounds', url: '/hellhounds' }, { name: 'Underworld', url: '/underworld' }, { name: 'About', url: '/about-us' }];

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">

        <Container maxWidth="xl">
          <Toolbar disableGutters>

            <Link to="/">
              <Typography sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex' }, fontWeight: 1000 }}>
                SolSnatchers
              </Typography>
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="menu-icon"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon></MenuIcon>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <Link to={page['url']}>
                    <MenuItem key={page["name"]} onClick={handleCloseNavMenu}>
                      <Typography sx={{color: 'white' }} textAlign="center">{page["name"]}</Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>


            <Link to="/">
              <Button sx={{ my: 2, color: 'white', display: { xs: 'flex', md: 'none' }, fontWeight: 1000 }}>
                SolSnatchers
              </Button>
            </Link>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Link to={page['url']}>
                  <MenuItem key={page["name"]} onClick={handleCloseNavMenu}>
                  <Typography
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block', ml: '1em', mr: '1em', hover: {backgroundColor: 'white'} }}
                  >
                    {page["name"]}
                  </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Box>
            <WalletMultiButton />
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Header;