import * as React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Button} from '@mui/material';

const WhereToBuy = () => {
  return (
    <Card className="cardBox">
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Where to buy?
          </Typography>
          <Button variant="outlined" size="large" href="https://solanart.io/collections/solsnatchers" target="_blank" rel="noopener noreferrer">
            <Typography gutterBottom variant="h5" component="div">
              Solanart.io
            </Typography>
          </Button>
          <Button variant="outlined" size="large" href="https://magiceden.io/marketplace/solsnatchers" target="_blank" rel="noopener noreferrer">
            <Typography gutterBottom variant="h5" component="div">
              Magiceden.io
            </Typography>
          </Button>
          <Button variant="outlined" size="large" href="https://solsea.io/c/615493e82f8d866403d7f6af" target="_blank" rel="noopener noreferrer">
            <Typography gutterBottom variant="h5" component="div">
              Solsea.io
            </Typography>
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default WhereToBuy;