import * as React from 'react';

import { Container, Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
const Snatchers = () => {
  return (
    <Container>
      <Paper sx={{mt: "1em"}}>
        <Typography variant="h1">
            Snatchers
        </Typography>
        <Typography>
          SolSnatchers are an infernal collection of 10,000 uniquely generated Grim Reapers existing eternally in the underworld of the Solana blockchain. Not only is each SolSnatcher unique, but they also grant their holders with special opportunities.
        </Typography>
      </Paper>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={4} xs={6}>
          <Paper sx={{mt: "1em"}}>
            <Typography variant="h2">
              10.000
            </Typography>
            <Typography>
              ever minted
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper sx={{mt: "1em"}}>
            <Typography variant="h2">
              220
            </Typography>
            <Typography>
              unique attributes
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper sx={{mt: "1em"}}>
            <Typography variant="h2">
              4 Mio
            </Typography>
            <Typography>
              possible combinations
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Snatchers;