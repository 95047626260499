import * as React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography} from '@mui/material';

const InfoCard = () => {
  return (
    <Card sx={{ maxWidth: 350 }} className="cardBox">
      <CardActionArea>
        <CardMedia
          component="img"
          image={require('./snatchers.gif')}
          alt="solsnatchers"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            SolSnatchers
          </Typography>
          <Typography variant="body2" color="text.secondary">
            SolSnatchers are an infernal collection of 10,000 uniquely generated Grim Reapers existing eternally in the underworld of the Solana blockchain.
            <br></br>
            <br></br>
            Explore the underworld to find out more. 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default InfoCard;