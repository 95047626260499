import * as React from 'react';

import { Container, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
const Underworld = () => {
  return (
    <Container>
        <Paper sx={{mt: "1em"}}>
        <Typography variant="h1">
          The underworld
        </Typography>
        <Typography>
          Also known as "portal" will be launched before helloween (approx. 31.10.2030) as formal aka Marko is still trying to fix his bugs.
        </Typography>
        <Typography>
          Most likely he will never be done doing so as he copied the code from somewhere else.
        </Typography>
        </Paper>
    </Container>
  );
};

export default Underworld;