import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Button, Grid } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <Paper sx={{margin: "2em 0em 0em 0em", textAlign: "center"}} className="footer">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
            <Button variant="outlined" size="large" href="https://twitter.com/solsnatchersnft" target="_blank" rel="noopener noreferrer">
              <TwitterIcon/>&nbsp; Twitter
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Typography>
              Copyright &copy;2021-{new Date().getFullYear()}.
            </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Footer;