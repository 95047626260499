import * as React from 'react';
import InfoCard from '../InfoCard';
import WhereToBuy from '../components/buy/WhereToBuy';

const Home = () => {
  return (
    <div>
      <InfoCard/>
      <WhereToBuy/>
    </div>
  );
};

export default Home;