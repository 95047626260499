import * as React from 'react';

import { Button, Container, Paper, Grid, Typography, Card, CardActionArea, CardMedia, CardContent } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

const AboutUs = () => {
  return (
    <Container>
      <Paper sx={{mt: "1em"}}>
        <Typography variant="h1">
          About us
        </Typography>
        <Typography>
          We are the SolSnatchers-Team:
        </Typography>
        <Typography>
          The original founders ran away with all the money for development.
        </Typography>
      </Paper>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 0, md: 0 }}>
        <Grid item md={4} xs={12}>
          <Card sx={{ maxWidth: 350 }} className="cardBox">
            <CardActionArea>
              <CardMedia
                component="img"
                image={require('./../images/reaperjim.jpg')}
                alt="reaperjim"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  @ReaperJim
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Co-Founder
                  <br></br>
                  <br></br>
                  Rumors say @ReaperJim bought a house with the money and lived happily ever after. Maybe also the aliens took him. Who knows? 
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card sx={{ maxWidth: 350 }} className="cardBox">
            <CardActionArea>
              <CardMedia
                component="img"
                image={require('./../images/juggles.jpg')}
                alt="juggles"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  @Juggles
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Co-Founder
                  <br></br>
                  <br></br>
                  He hates social media.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card sx={{ maxWidth: 350 }} className="cardBox">
            <CardActionArea>
              <CardMedia
                component="img"
                image={require('./../images/bantilope.jpg')}
                alt="bantilope"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  @Bantilope
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Dev-Lead
                  <br></br>
                  <br></br>
                  Deleted his discord account. Github (snatchy786)
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card sx={{ maxWidth: 350 }} className="cardBox">
            <CardActionArea>
              <CardMedia
                component="img"
                image={require('./../images/miles.jpg')}
                alt="miles"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  @Miles
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Visual-Art-Lead
                  <br></br>
                  <br></br>
                  Never heard of him.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card sx={{ maxWidth: 350 }} className="cardBox">
            <CardActionArea>
              <CardMedia
                component="img"
                image={require('./../images/bigjunior.jpg')}
                alt="bigjunior"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  @BigJunior
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Marketing
                  <br></br>
                  <br></br>
                  You don't need marketing if you rug before.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Paper sx={{mt: "1em"}}>
        <Typography>
          If you have any info on these guys and want to expose them join our discord.
        </Typography>
        <Typography>
          <Button variant="outlined" size="large" href="https://discord.gg/A9zJsvPyMd" target="_blank" rel="noopener noreferrer">
            <ChatIcon/>&nbsp; Discord
          </Button>
        </Typography>
      </Paper>
    </Container>
  );
};

export default AboutUs;