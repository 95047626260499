import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Paper, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const NotFound = () => {
  return (
    <Paper sx={{margin: "2em 2em 2em 2em", textAlign: "center"}}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h1">
            Page not found.
          </Typography>
        </Grid>
        <Grid item xs={12}>
            <Button variant="outlined" size="large" href="/">
            <HomeIcon/>&nbsp; Home
          </Button>
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
    </Paper>

  );
};

export default NotFound;