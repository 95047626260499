import * as React from "react";
import { useRoutes } from "react-router-dom";
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import AboutUs from "./pages/AboutUs";
import Snatchers from "./pages/Snatchers";
import Hellhounds from "./pages/Hellhounds";
import Underworld from "./pages/Underworld";

const Routes = () => {
  return useRoutes([
    {
      path: '/',
      element: <Home />
    },
    {
      path: 'snatchers',
      element: <Snatchers/>
    },
    {
      path: 'hellhounds',
      element: <Hellhounds/>
    },
    {
      path: 'underworld',
      element: <Underworld/>
    },
    {
      path: 'about-us',
      element: <AboutUs />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]);
};

export default Routes;